import { TestimonialItem } from "../../utils/types";

const index = ({
  image,
  description,
  clientName,
  profession,
}: TestimonialItem) => {
  let imageSrc;
  try {
    imageSrc = require(`../../config/images/${image}`);
  } catch (e) {
    imageSrc = "";
  }
  return (
    <div className="owl-item">
      <div className="text-center pb-4">
        <img
          className="img-fluid mx-auto rounded-circle"
          src={imageSrc}
          style={{ width: "100px", height: "100px", marginRight: "100px" }}
          alt="Image"
        />
        <div className="testimonial-text bg-light rounded p-4 mt-n5">
          <p
            className="mt-5"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
          <div className="testimonial-footer">
            {" "}
            <h4 className="text-truncate">{clientName}</h4>
            <i>{profession}</i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
