import { CareerItem } from "../../utils/types";

const index = ({ image, title, description }: CareerItem) => {
  let imageSrc;
  try {
    imageSrc = require(`../../config/images/${image}`);
  } catch (e) {
    imageSrc = "";
  }
  return (
    <div className="owl-item">
      <div className="text-center pb-4 bg-light rounded p-4">
        <img
          className="mx-auto"
          src={imageSrc}
          style={{ width: "300px", height: "150px", marginRight: "100px" }}
          alt="Image"
        />
        <h4 className="text-truncate mt-4">{title}</h4>
        <p
          className="mt-3"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </div>
    </div>
  );
};

export default index;
