const env = {
  prod: "production",
  dev: "development",
};

const NODE_ENV = (window as any)._env_.NODE_ENV;

const IS_PRODUCTION = NODE_ENV === env.prod;

const BACKEND_URL = "https://tm-email-api.azurewebsites.net/";

export { BACKEND_URL };
